import React from 'react';
import Layout from '../components/layout/Layout';
import SplitSection from "../components/SplitSection";
import ListItem from "../components/ListItem";
import Hero from "../components/Hero";
import { Helmet } from 'react-helmet';
import corpRejuvenation from '../images/corp-rejuvenation.jpg';
import strategicConsulting from '../images/strategic-consulting.jpg';
import interimServices from '../images/interim-services.jpg';
import insolvencyServices from '../images/insolvency-services.jpg';
import ShareImg from "../images/share.png";

export default () => (
  <Layout>
    <Hero
      coverImage="https://images.unsplash.com/photo-1541746972996-4e0b0f43e02a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2550&q=80"
      primaryContent={
        <span>Our Service Offerings</span>
      }
    />

    <SplitSection
      id="services"
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h2 class="text-2xl font-medium text-gray-900 title-font mb-4">
            Strategic Consulting
          </h2>
          <p class="leading-relaxed mb-4">
            HMG believes that the best way to prevent a crisis is to properly
            plan for it; therefore early intervention is often the key to quick
            and successful correction. If you have been noticing a slide in
            margin, up tick in expenses, operational issues or just not
            generating what you think your company should be generating – we may
            be just what the doctor ordered. Our team has the ability to focus
            on working capital, supply chain, and operational management – and
            work side by side with your existing team to make them more
            efficient as well.
          </p>
          <p class="leading-relaxed mb-4">
            Our team members have extensive experience in finance, manufacturing
            and distribution, and can bring that experience to your group on a
            focused and temporary basis, leaving you with tools that you will
            continue to use long after we are gone.
          </p>
        </div>
      }
      secondarySlot={
        <img
          src={ strategicConsulting }
          alt="strategic consulting"
        />
      }
    />

    <SplitSection
      reverseOrder
      shaded
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <h2 class="text-2xl font-medium text-gray-900 title-font mb-4">
            Corporate Rejuvenation
          </h2>
          <p className="mt-8 text-xl font-light leading-relaxed">
            For over thirty years, our approach and success has earned
            far-reaching respect for our abilities to work with troubled or
            under-performing companies. In these most urgent situations, it is
            important to work quickly with management to stabilize finances and
            improve operations to preserve value. We take into consideration
            both short and long-term views, and our efforts are to aggressively
            protect ownership interest within the law, and to improve results
            for the benefit of all stakeholders. While all companies and
            situations are unique, most of our projects contain one or more of
            the following services:
          </p>
        </div>
      }
      secondarySlot={
        <img src={ corpRejuvenation } alt=""/>
      }
      optionalList={
        <div className="md:flex">
          <div className="lg:w-1/2 p-8">
            <ListItem textSlot={<>Creating and delivering detailed cash flow projections and analysis. The industry standard is 13 weeks, but we work with management to determine the appropriate projection period.</>}/>
            <ListItem textSlot={<>Negotiating with secured debt holders. We work with both management and the company’s counsel to develop an acceptable forbearance agreement to all stakeholders.</> }/>
            <ListItem textSlot={<>Negotiating new debt. In certain instances, the incumbent lender may be adequately fatigued and refinancing is necessary. Our extensive network allows us to bring the right lender in for you and your situation.</> }/>
          </div>
          <div className="lg:w-1/2 p-8">
            <ListItem textSlot={<>Creating detailed, dynamic and comprehensive business models in conjunction with existing management, including contingency planning, stress testing, option analysis and ultimately the implementation of the agreed plan.</> }/>
            <ListItem textSlot={<>Negotiating with unsecured debt holders. Cash may be tight at the beginning of the process. We work with management on cash allocation and negotiation of payment plans acceptable to all parties.</>}/>
            <ListItem textSlot={<>Planning a Strategic Exit. In some instances, ownership is unable to participate in the turnaround process.  We work with both ownership and the management team to find the right home for the company and its team.</>}/>
          </div>
        </div>
      }
    />

    <SplitSection
      id="services"
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h2 class="text-2xl font-medium text-gray-900 title-font mb-4">
            Interim Support/Management
          </h2>
          <p class="leading-relaxed mb-4">
            During transitional times, businesses require hands-on experienced
            leadership. By nature, this support is on an interim basis to assist
            the company through whatever challenge lies ahead. Whether the need
            is driven through a special circumstance or while you search for a 
            replacement candidate, we help companies through a
            smooth transitional process, transfer knowledge to existing or new
            employees, and help build a foundation for continued success. Some
            of our projects have included some of these interim roles:
          </p>
        </div>
      }
      secondarySlot={
        <img
          src={ interimServices }
          alt="Interim Services"
        />
      }
      optionalList={
        <div className="md:flex">
          <div className="lg:w-1/2 p-8">
            <ListItem textSlot={<>C-Suite roles including Chief Executive Officer, Chief Operating Officer or Chief Financial Officer.</>}/>
            <ListItem textSlot={<>Project management and/or consultants related to the due diligence on a strategic acquisition by your company.</> }/>
          </div>
          <div className="lg:w-1/2 p-8">
            <ListItem textSlot={<>Senior level positions including Controller, Accounting Manager, Operations Manager, Manufacturing Manager.</> }/>
            <ListItem textSlot={<>Chief Restructuring Officer – When companies are driving towards a major restructuring, a dedicated resource to handle specific restructuring related items to free existing management to operate company.</>}/>
          </div>
        </div>
      }
    />

    <SplitSection
      reverseOrder
      shaded
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <h2 class="text-2xl font-medium text-gray-900 title-font mb-4">
            Insolvency Services
          </h2>
          <p class="leading-relaxed mb-4">
            Hollis Meddings Group can provide end-to-end case management in the
            event of insolvency. In some instances, ownership may want to have
            continued involvement, more control and less expense than a
            Bankruptcy or Receivership. HMG has extensive experience in this
            area, and was 1 of 33 handpicked Receivers across the country that
            contracted with the SBA.
          </p>
          <p class="leading-relaxed mb-4">
            We routinely run a similar but less expensive procedure called an
            Assignment for the Benefit of Creditors (“ABC"). An ABC runs by the
            same rules and preferences that are found in a Bankruptcy case, but
            for exceptionally less money. This process is especially effective
            in situations where there may be substantial returns to unsecured
            creditors. In this process, we perform the following tasks:
          </p>
        </div>
      }
      secondarySlot={
        <img src={ insolvencyServices } alt="insolvency services"/>
      }
      optionalList={
        <div className="md:flex">
          <div className="w-full lg:w-1/2 p-8">
            <ListItem textSlot={<>Assignment for Benefit of Creditors notification letter, including a claim form with instructions.</>}/>
            <ListItem textSlot={<>Claim proof and resolution analysis, including separation of preference claims</>}/>
            <ListItem textSlot={<>Proper liquidation of collected assets to maximize value.</>}/>
            <ListItem textSlot={<>Compete record keeping, fiduciary services and communication protocols.</>}/>
          </div>
          <div className="w-full lg:w-1/2 p-8">
            <ListItem textSlot={<>Collection of potential submitted claims</>}/>
            <ListItem textSlot={<>Identification and collection of corporate assets for liquidation</>}/>
            <ListItem textSlot={<>Distribution of assets to approved claims in approved preferences</>}/>
            <ListItem textSlot={<>Final distribution, final dissolution of corporate entity</>}/>
          </div>
        </div>
      }
    />
    <Helmet>
      <meta charSet="utf-8" />
      <title>Service Offerings | Hollis Meddings Group</title>
      <meta name="description" content="Hollis Meddings Group understands the practical realities of doing business in uncertain times as well as the challenges of running a family based entity where management and ownership are often the same."/>
      <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta property="og:title" content="Hollis Meddings Group"/>
      <meta property="og:description" content="Hollis Meddings Group understands the practical realities of doing business in uncertain times as well as the challenges of running a family based entity where management and ownership are often the same."/>
      <meta property="og:image" content={ 'https://www.hollismeddings.com'+ShareImg }/>
      <meta property="og:url" content="https://www.hollismeddings.com"/>
      <meta name="twitter:card" content={ 'https://www.hollismeddings.com'+ShareImg }/>
      <meta property="og:site_name" content="Hollis Meddings Group"/>
      <meta name="twitter:image:alt" content="Hollis Meddings Group"/>
    </Helmet>
  </Layout>
);